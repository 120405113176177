.am-picker-col {
  display: block;
  position: relative;
  height: 6.346667rem;
  overflow: hidden;
  width: 100%;
}

.am-picker-col-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  padding: 2.72rem 0;
}

.am-picker-col-item {
  touch-action: manipulation;
  text-align: center;
  font-size: 0.426667rem;
  height: 0.906667rem;
  line-height: 0.906667rem;
  color: #000;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.am-picker-col-item-selected {
  font-size: 0.453333rem;
}

.am-picker-col-mask {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  margin: 0 auto;
  width: 100%;
  z-index: 3;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.6)), linear-gradient(to top, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.6));
  background-position: top, bottom;
  background-size: 100% 2.72rem;
  background-repeat: no-repeat;
}

.am-picker-col-indicator {
  box-sizing: border-box;
  width: 100%;
  height: 0.906667rem;
  position: absolute;
  left: 0;
  top: 2.72rem;
  z-index: 3;
  border-top: 1PX solid #ddd;
  border-bottom: 1PX solid #ddd;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-picker-col-indicator {
    border-top: none;
  }

  html:not([data-scale]) .am-picker-col-indicator::before {
    content: '';
    position: absolute;
    background-color: #ddd;
    display: block;
    z-index: 1;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    width: 100%;
    height: 1PX;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
}

@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .am-picker-col-indicator::before {
    -webkit-transform: scaleY(0.33);
    transform: scaleY(0.33);
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-picker-col-indicator {
    border-bottom: none;
  }

  html:not([data-scale]) .am-picker-col-indicator::after {
    content: '';
    position: absolute;
    background-color: #ddd;
    display: block;
    z-index: 1;
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1PX;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
}

@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .am-picker-col-indicator::after {
    -webkit-transform: scaleY(0.33);
    transform: scaleY(0.33);
  }
}

.am-picker {
  display: flex;
  align-items: center;
}

.am-picker-item {
  flex: 1 1;
  text-align: center;
}