.am-pagination-wrap {
  font-size: 0.48rem;
  color: #000;
  background: none;
  text-align: center;
}

.am-pagination-wrap .active {
  color: #108ee9;
}

.am-pagination-wrap-btn {
  text-align: center;
}

.am-pagination-wrap-btn-prev {
  text-align: left;
}

.am-pagination-wrap-btn-next {
  text-align: right;
}

.am-pagination-wrap-dot {
  display: inline-block;
  zoom: 1;
}

.am-pagination-wrap-dot > span {
  display: block;
  width: 0.213333rem;
  height: 0.213333rem;
  margin-right: 0.133333rem;
  border-radius: 50%;
  background: #ccc;
}

.am-pagination-wrap-dot-active > span {
  background: #888;
}