/* flexbox */

.am-flexbox {
  text-align: left;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.am-flexbox.am-flexbox-dir-row {
  flex-direction: row;
}

.am-flexbox.am-flexbox-dir-row-reverse {
  flex-direction: row-reverse;
}

.am-flexbox.am-flexbox-dir-column {
  flex-direction: column;
}

.am-flexbox.am-flexbox-dir-column .am-flexbox-item {
  margin-left: 0;
}

.am-flexbox.am-flexbox-dir-column-reverse {
  flex-direction: column-reverse;
}

.am-flexbox.am-flexbox-dir-column-reverse .am-flexbox-item {
  margin-left: 0;
}

.am-flexbox.am-flexbox-nowrap {
  flex-wrap: nowrap;
}

.am-flexbox.am-flexbox-wrap {
  flex-wrap: wrap;
}

.am-flexbox.am-flexbox-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.am-flexbox.am-flexbox-justify-start {
  justify-content: flex-start;
}

.am-flexbox.am-flexbox-justify-end {
  justify-content: flex-end;
}

.am-flexbox.am-flexbox-justify-center {
  justify-content: center;
}

.am-flexbox.am-flexbox-justify-between {
  justify-content: space-between;
}

.am-flexbox.am-flexbox-justify-around {
  justify-content: space-around;
}

.am-flexbox.am-flexbox-align-start {
  align-items: flex-start;
}

.am-flexbox.am-flexbox-align-end {
  align-items: flex-end;
}

.am-flexbox.am-flexbox-align-center {
  align-items: center;
}

.am-flexbox.am-flexbox-align-stretch {
  align-items: stretch;
}

.am-flexbox.am-flexbox-align-baseline {
  align-items: baseline;
}

.am-flexbox.am-flexbox-align-content-start {
  align-content: flex-start;
}

.am-flexbox.am-flexbox-align-content-end {
  align-content: flex-end;
}

.am-flexbox.am-flexbox-align-content-center {
  align-content: center;
}

.am-flexbox.am-flexbox-align-content-between {
  align-content: space-between;
}

.am-flexbox.am-flexbox-align-content-around {
  align-content: space-around;
}

.am-flexbox.am-flexbox-align-content-stretch {
  align-content: stretch;
}

.am-flexbox .am-flexbox-item {
  box-sizing: border-box;
  flex: 1 1;
  margin-left: 0.213333rem;
  min-width: 0.266667rem;
}

.am-flexbox .am-flexbox-item:first-child {
  margin-left: 0;
}