.am-calendar .animate {
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.am-calendar .fade-enter {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.am-calendar .fade-leave {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes slideInUp {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes slideInUp {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@-webkit-keyframes slideInDown {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes slideInDown {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@-webkit-keyframes slideInLeft {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes slideInLeft {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@-webkit-keyframes slideInRight {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes slideInRight {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.am-calendar .slideV-enter {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

.am-calendar .slideV-leave {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

.am-calendar .slideH-enter {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

.am-calendar .slideH-leave {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

.am-calendar .mask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
}

.am-calendar .content {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background: #fff;
}

.am-calendar .header {
  margin: 0.133333rem;
  display: flex;
  flex-shrink: 0;
  align-items: center;
}

.am-calendar .header .title {
  text-align: center;
  width: 100%;
  font-size: 0.426667rem;
  font-weight: bold;
}

.am-calendar .header .left {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.213333rem;
  height: 0.64rem;
  left: 0.133333rem;
  top: 0.133333rem;
  color: #068EEF;
}

.am-calendar .header .right {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.213333rem;
  height: 0.64rem;
  right: 0.133333rem;
  top: 0.133333rem;
  color: #068EEF;
  font-size: 0.373333rem;
}

.am-calendar .timePicker {
  border-top: 1PX #ccc solid;
}

.am-calendar .week-panel {
  background: #fff;
  display: flex;
  flex-shrink: 0;
  padding: 0 0.053333rem;
  border-bottom: 1PX #ddd solid;
}

.am-calendar .week-panel .cell {
  height: 0.64rem;
  display: flex;
  width: 14.28571429%;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 0.373333rem;
}

.am-calendar .week-panel .cell-grey {
  color: #bbb;
}

.am-calendar .date-picker {
  display: flex;
  flex-direction: column;
  background: #eee;
  padding-bottom: env(safe-area-inset-bottom);
  flex: 1 1;
  min-height: 0;
}

.am-calendar .date-picker .wrapper {
  height: auto;
  position: relative;
  flex: 1 1;
  min-height: 0;
}

.am-calendar .date-picker .months {
  background: #fff;
}

.am-calendar .date-picker .load-tip {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  left: 0;
  right: 0;
  padding: 0.266667rem 0;
  top: -1.066667rem;
  color: #bbb;
}

.am-calendar .confirm-panel {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  background: #f7f7f7;
  padding: 0.213333rem 0.4rem;
  border-top: #ddd 1PX solid;
}

.am-calendar .confirm-panel .info {
  font-size: 0.32rem;
}

.am-calendar .confirm-panel .info p {
  margin: 0;
}

.am-calendar .confirm-panel .info p + p {
  margin-top: 0.213333rem;
}

.am-calendar .confirm-panel .info .grey {
  color: #bbb;
}

.am-calendar .confirm-panel .button {
  text-align: center;
  width: 2.133333rem;
  margin: 0 0 0 auto;
  padding: 0.213333rem 0;
  border-radius: 0.133333rem;
  color: #fff;
  font-size: 0.48rem;
  background: #108ee9;
}

.am-calendar .confirm-panel .button-disable {
  color: #bbb;
  background: #ddd;
}

.am-calendar .confirm-panel .button-full {
  width: 100%;
  text-align: center;
}

.am-calendar .time-picker {
  flex-shrink: 0;
  text-align: center;
  background: #fff;
}

.am-calendar .time-picker .title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.173333rem;
  font-size: 0.426667rem;
  border-top: 1PX #ddd solid;
  border-bottom: 1PX #ddd solid;
}

.am-calendar .single-month {
  padding: 0;
}

.am-calendar .single-month .month-title {
  margin: 0;
  padding: 0.56rem 0 0.16rem 0.4rem;
}

.am-calendar .single-month .row {
  display: flex;
  align-items: baseline;
}

.am-calendar .single-month .row .cell {
  display: flex;
  flex-direction: column;
  width: 14.28571429%;
  justify-content: center;
  align-items: center;
}

.am-calendar .single-month .row .cell .date-wrapper {
  display: flex;
  height: 0.933333rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.053333rem;
}

.am-calendar .single-month .row .cell .date-wrapper .date {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0.933333rem;
  height: 0.933333rem;
  flex-shrink: 0;
  color: #000;
  font-size: 0.453333rem;
  font-weight: bold;
}

.am-calendar .single-month .row .cell .date-wrapper .disable {
  color: #bbb;
  background: #eee;
  border: none;
  border-radius: 100%;
}

.am-calendar .single-month .row .cell .date-wrapper .grey {
  color: #bbb;
}

.am-calendar .single-month .row .cell .date-wrapper .important {
  border: 1PX #ddd solid;
  border-radius: 100%;
}

.am-calendar .single-month .row .cell .date-wrapper .left,
.am-calendar .single-month .row .cell .date-wrapper .right {
  border: none;
  width: 100%;
  height: 0.933333rem;
}

.am-calendar .single-month .row .cell .date-wrapper .date-selected {
  border: none;
  background: #108ee9;
  color: #fff;
  font-size: 0.453333rem;
}

.am-calendar .single-month .row .cell .date-wrapper .selected-start {
  border-radius: 100% 0 0 100%;
}

.am-calendar .single-month .row .cell .date-wrapper .selected-single {
  border-radius: 100%;
}

.am-calendar .single-month .row .cell .date-wrapper .selected-middle {
  border-radius: 0;
}

.am-calendar .single-month .row .cell .date-wrapper .selected-end {
  border-radius: 0 100% 100% 0;
}

.am-calendar .single-month .row .cell .info {
  height: 0.4rem;
  width: 100%;
  padding: 0 0.133333rem;
  font-size: 0.266667rem;
  color: #888;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
}

.am-calendar .single-month .row .cell .date-selected {
  color: #108ee9;
}

.am-calendar .single-month .row + .row {
  margin-top: 0.16rem;
}

.am-calendar .single-month .row-xl + .row-xl {
  margin-top: 0.56rem;
}

.am-calendar .shortcut-panel {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.8rem;
  border-top: #ddd 1PX solid;
  height: 1.12rem;
}

.am-calendar .shortcut-panel .item {
  display: inline-block;
  color: #108ee9;
  font-size: 0.426667rem;
}